<template>
  <div class="login-wrap">
    <img
      src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/1T5D3pX9B1t8oQAycKN4JOq7gTSVCRNt.png"
      alt=""
    />
    <p>登录</p>
    <div class="handle-box">
      <van-form @submit="loginFn" ref="loginForm">
        <div class="item">
          <p>
            <img
              src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/vNOXNsvRW4rTB1gfbmLygA2oU1fSX5WX.png"
              alt=""
            />
            手机号
          </p>
          <div class="input">
            <van-field
              v-model="form.phone"
              type="tel"
              :maxlength="11"
              placeholder="请输入手机号"
            />
          </div>
        </div>
        <div v-if="!usePassword" class="item">
          <p>
            <img
              src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/Qubao0XnpCbUxVypAOQuMWj0Jvrlfbkm.png"
              alt=""
            />
            验证码
          </p>
          <div class="input verify-code">
            <van-field v-model="form.code" placeholder="请输入验证码" />
            <div
              :class="['send-btn', sending ? 'sending' : '']"
              @click="getCode"
            >
              {{ sending ? `${second}s` : "获取" }}
            </div>
          </div>
        </div>
        <div v-else class="item">
          <p>
            <img
              src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/Qubao0XnpCbUxVypAOQuMWj0Jvrlfbkm.png"
              alt=""
            />
            密码
          </p>
          <div class="input verify-code">
            <van-field
              :type="showPassword ? 'text' : 'password'"
              v-model="form.password"
              placeholder="请输入密码"
              :right-icon="showPassword ? 'eye-o' : 'closed-eye'"
              @click-right-icon="() => (showPassword = !showPassword)"
            />
          </div>
        </div>
        <div class="agreement">
          <van-checkbox v-model="agreement" checked-color="#1C97FF">
            我已阅读并同意
            <span class="link" @click.stop="toAgreement">
              《用户协议及隐私政策》
            </span>
          </van-checkbox>
        </div>
        <van-button
          class="large-btn"
          size="large"
          :loading="loading"
          round
          color="linear-gradient(to right,#46C4FF, #1C97FF)"
          type="info"
          native-type="submit"
          >登录
        </van-button>

        <!--  -->
      </van-form>

      <div
        class="flex justify-center items-center text-14 text-hex-1c97ff py-12"
        @click="usePassword = !usePassword"
      >
        <van-icon class="mr-8" name="exchange" size="0.5rem" />
        <span>{{ usePassword ? "验证码登录" : "密码登录" }}</span>
      </div>
    </div>

    <!-- 留白 -->
    <div class="h-48"></div>

    <!-- 版权 -->
    <div class="flex-1"></div>
    <div class="flex justify-center py-16">
      <div class="text-hex-999 text-center">
        重庆斯福兴华科技有限公司<br />©️版权所有
      </div>
    </div>

    <!--  -->
  </div>
</template>
<script>
import { login, smsLgcode } from "@/api";
import { mapActions } from "vuex";
import { clearCache, setToken } from "@/utils/app";

export default {
  data() {
    return {
      agreement: false,
      loading: false,
      sending: false,
      second: 60,
      form: {
        phone: process.env.NODE_ENV != "production" ? "16783738639" : null,
        password: process.env.NODE_ENV != "production" ? "738639" : null,
        /* phone: process.env.NODE_ENV != "production" ? "17399999999" : null,
        password: process.env.NODE_ENV != "production" ? "999999" : null, */
      },
      usePassword: true,
      showPassword: false,
      /* rules: {
        phone: [
          { required: true, message: "请输入手机号" },
          {
            validator: (v) => {
              return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(v);
            },
            message: "请输入正确的手机号",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "onChange" },
        ],
      }, */
    };
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    toAgreement() {
      global.open("https://h5.songchewang.com/#/agreement/register");
    },
    // 获取验证码
    async getCode() {
      let { door } = this.$route.query;
      if (this.sending) return;
      const mobilePass = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.form.phone);
      if (!mobilePass && !door) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      try {
        await smsLgcode(this.form.phone);
        this.$toast.success("验证码已发送，请注意查收！");
        this.sending = true;
        this.itv = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.sending = false;
            clearInterval(this.itv);
          }
        }, 1000);
        return Promise.resolve(true);
      } catch (error) {
        this.sending = false;
        this.$errMsg(error);
        return Promise.reject(error);
      }
    },
    async loginFn() {
      try {
        this.loading = true;

        clearCache();

        const res = await login({
          username: this.form.phone,
          smsCode: this.form.code,
          deviceId: this.form.phone,
          unionId: this.form.phone,
          agree: this.agreement,
          password: this.form.password,
          usePassword: this.usePassword,
        });
        setToken(res?.data?.tokenInfo?.access_token);
        this.setUserInfo(res?.data || {});
        this.$toast.success("登录成功");
        this.$router.push("/home");
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },
    // auto login
    async autoLogin() {
      let { door } = this.$route.query;
      if (!door) return;

      const u = {
        userId: "864129501167095808",
        username: "16783738639",
        realName: "张敏",
        merchantNo: "503002",
        merchantName: "追云",
        isActive: null,
        roles: {},
        tokenInfo: {
          access_token: "7838b3d5-ddc8-4346-a669-375b36891eb1",
          refresh_token: "3804fdbf-b611-465e-8892-f8a0e9d8c4ec",
          scope: "userProfile",
          token_type: "bearer",
          expires_in: 86399,
        },
      };
      setToken(u.tokenInfo.access_token);
      this.setUserInfo(u);

      this.$router.push("/home");

      //
    },
  },
  created() {
    if (process.env.NODE_ENV == "development") this.autoLogin();
  },
};
</script>
<style lang="scss" scoped>

.login-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-image: url("https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/0A3chSpSLxJtLbV5SGGzzQEpe5M03LCp.png");
  background-size: 750px 1506px;
  background-position: 0 0;
  & > img {
    width: 324px;
    height: 80px;
    display: block;
    margin: 96px auto 0 auto;
  }
  & > p {
    font-size: 36px;
    color: #333;
    line-height: 36px;
    height: 36px;
    position: relative;
    margin-top: 112px;
    font-weight: bold;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 106%;
      height: 12px;
      background: rgba(24, 144, 255, 0.29);
      border-radius: 6px;
      left: -3%;
      bottom: -6px;
      z-index: 0;
    }
  }
  .handle-box {
    width: 686px;
    // height: 688px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(79, 183, 255, 0.17);
    border-radius: 48px;
    margin: 0 auto;
    margin-top: 34px;
    padding: 0 48px;
    padding-top: 48px;
    .item {
      &:first-child {
        margin-bottom: 48px;
      }
      & > p {
        display: flex;
        align-items: center;
        img {
          height: 32px;
          width: 32px;
          margin-right: 16px;
        }
        font-size: 28px;
        color: #333333;
      }
      .input {
        margin-top: 24px;
        background: #eff8fd;
        border-radius: 48px;
        width: 590px;
        height: 96px;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 48px;
        .van-cell {
          padding: 0;
          background: #eff8fd;
          &::after {
            content: unset;
          }
        }
        &.verify-code {
          display: flex;
          justify-content: space-between;
          .send-btn {
            width: 128px;
            height: 28px;
            line-height: 28px;
            border-left: 2px solid #1890ff;
            font-size: 28px;
            font-weight: 400;
            color: #1890ff;
            text-align: center;
            &.sending {
              color: #999;
            }
          }
        }
      }
    }
    .agreement {
      margin-top: 48px;
      display: flex;
      align-items: center;

      font-size: 28px;
      .link {
        color: #1890ff;
      }
    }
    /deep/.van-checkbox__icon {
      font-size: 32px;
    }
    .large-btn {
      margin-top: 50px;
    }
  }
}

</style>
